<template>
    <div style="margin-bottom:2rem;">
        <div class="title1">
            <p class="title_top">STAFF PRESENCE</p>
            <p class="title_content">员工风采</p>
        </div>
        <div>
            <div class="col5 wow bounceInLeft hvr-rotate" v-for="item in data" :key="item" style="display:inline-block;margin-top:2rem">
                <div class="col9" style="margin:0 auto">
                    <img style="max-height:500px;width:100%" v-lazy="require('../../assets/manpower/show/'+item)">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            data:['8.jpg','2.jpg','3.jpg','4.png','5.png','6.png','7.png','1.jpg',]
        }
    }
}
</script>